<template>
  <div id="app">
<!--    <div class="top-nav">-->
<!--      <div class="content" style="display: flex;justify-content: space-between;">-->
<!--        <img src="./assets/logo.png" alt="">-->
<!--        <div>-->
<!--          <router-link to="/">首页</router-link> |-->
<!--          <router-link to="/about">关于我们</router-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <NavComp></NavComp>
    <router-view/>
    <FooterComp></FooterComp>
  </div>
</template>
<script>
import FooterComp from '@/components/FooterComp'
import NavComp from '@/components/NavComp'


export default {
  components: {
    FooterComp,
    NavComp
  },
  created () {
    const _this = this;
    window.addEventListener('resize', function (e) {
      _this.$store.commit('common/SET_bodyWidth', document.body.clientWidth)
    })
  }
}
</script>
<style lang="scss">
body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}
.content{
  width: 1200px;
  margin: 0 auto;
}
.topbgbox{
  display: flex;
  justify-content: center;
  overflow: hidden;
  min-width: 1200px;
}
</style>
