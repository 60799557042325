<template>
  <div class="describe-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DescribeComp',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.describe-box{
  width: 1200px;
  height: 120px;
  background: rgba(255,255,255,0.9);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  border-radius: 8px;
  border: 2px solid #FFFFFF;
  backdrop-filter: blur(10px);
}
</style>
