<template>
  <div class="footer">
    <div style="height: 285px;border-bottom:  1px solid #B1B2B5;">
      <div class="content footer-content">
        <div style="text-align: left; margin-bottom: 32px;">
          <span class="footer-content-title">联系我们</span>
          <span class="footer-content-en">CONTACT US</span>
          <span class="footer-content-line"></span>
        </div>
        <div class="company-item">
          <img src="../assets/icon/address.png" alt="">
          <span class="company-info">广州市天河区五山路248号金山大厦1808-1810</span>
        </div>
        <div class="company-item">
          <img src="../assets/icon/call.png" alt="">
          <span class="company-info">(020)3780 7284</span>
        </div>
        <div class="company-item">
          <img src="../assets/icon/email.png" alt="">
          <span class="company-info">admin@chenxin6.com</span>
        </div>
      </div>
    </div>
    <div style="height: 125px">
      <div class="content" style="padding-top: 21px;">
        <div class="cert-box" style="margin-bottom: 10px;">
          <div @click="handleImageViewer(index,certList1)" class="cert-item" v-for="(item,index) in certList1" :key="index">{{item.name}}</div>
        </div>
        <div class="cert-box" style="margin-bottom: 18px;">
          <div @click="handleImageViewer(index,certList2)" class="cert-item" v-for="(item,index) in certList2" :key="index">{{item.name}}</div>
        </div>
        <div class="bei-an"><a style="color: #fff" href="https://beian.miit.gov.cn/">备案号：粤ICP备20061742号-1</a></div>
      </div>
    </div>
    <MyImageViewer ref="elimageviewer"
                   v-show="imgViewerVisible"
                   :on-close="closeImgViewer"
                   :url-list="imgList"></MyImageViewer>
  </div>
</template>

<script>
import MyImageViewer from '@/components/MyImageViewer'
export default {
  name: 'FooterComp',
  components: {
    MyImageViewer,
  },
  data () {
    return {
      imgList: [],
      imgViewerVisible: false,
      certList1: [
        {
          name: '高新技术企业证书',
          imgs: ['/cert/高新技术企业证书.png']
        },
        {
          name: '广东省安全技术防范系统设计、施工、维修资格证',
          imgs: ['/cert/广东省安全技术防范系统设计、施工、维修资格证.png']
        },
        {
          name: '实用新型专利证书',
          imgs: [
            '/cert/实用新型专利证书_a1.png',
            '/cert/实用新型专利证书_a2.png',
            '/cert/实用新型专利证书_b1.png',
            '/cert/实用新型专利证书_b2.png',
            '/cert/实用新型专利证书_c1.png',
            '/cert/实用新型专利证书_c2.png',
            '/cert/实用新型专利证书_d1.png',
            '/cert/实用新型专利证书_d2.png'
          ]
        },
      ],
      certList2: [
        {
          name: '广东省计算机用户协会副会长单位',
          imgs: [
            '/cert/广东省计算机用户协会副会长单位.png'
          ]
        },
        {
          name: '计算机软件著作权登记证书',
          imgs: [
            '/cert/计算机软件著作权登记证书1.png',
            '/cert/计算机软件著作权登记证书2.png',
            '/cert/计算机软件著作权登记证书3.png',
            '/cert/计算机软件著作权登记证书4.png',
            '/cert/计算机软件著作权登记证书5.png',
            '/cert/计算机软件著作权登记证书6.png',
            '/cert/计算机软件著作权登记证书7.png',
            '/cert/计算机软件著作权登记证书8.png',
            '/cert/计算机软件著作权登记证书9.png',
            '/cert/计算机软件著作权登记证书10.png',
            '/cert/计算机软件著作权登记证书11.png',
            '/cert/计算机软件著作权登记证书12.png',
            '/cert/计算机软件著作权登记证书13.png',
            '/cert/计算机软件著作权登记证书14.png',
            '/cert/计算机软件著作权登记证书15.png'
          ]
        },
        {
          name: '广东省安防通信联盟副会长单位',
          imgs: [
            '/cert/广东省安防通信联盟副会长单位.png'
          ]
        },
        {
          name: '广州现代信息工程职业技术学院实习实训基地',
          imgs: [
            '/cert/广州现代信息工程职业技术学院实习实训基地.png'
          ]
        },
      ]
    }
  },
  methods: {
    closeImgViewer () {
      this.imgViewerVisible = false
    },
    handleImageViewer (index, list) {
      this.imgList = list[index].imgs
      const currentUrl = list[index].imgs[0]
      if (currentUrl) {
        this.$refs.elimageviewer.index = 0
        this.imgViewerVisible = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer{
  height: 411px;
  background: #333333;
}
.footer-content{
  padding-top: 65px;
  .footer-content-title{
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 31px;
  }
  .footer-content-en{
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #BBBDC1;
    line-height: 16px;
    margin: 0 13px;
  }
  .footer-content-line{
    display: inline-block;
    width: 163px;
    height: 1px;
    border-bottom: 1px solid #B1B2B5;
  }
  .company-item{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .company-info{
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #DCDCDC;
    line-height: 21px;
    margin-left: 9px;
  }
}
.cert-box{
  display: flex;
  justify-content: space-around;
  .cert-item{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #DCDCDC;
    line-height: 19px;
    cursor: pointer;
  }
}
.bei-an{
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #CCCCCC;
  line-height: 19px;
}
</style>
