<template>
  <div class="top-nav">
    <div class="content" style="display: flex; justify-content: space-between">
      <img src="../assets/logo.png" alt="" />
      <div style="display: flex; align-items: center">
        <div style="margin-left: 56px; position: relative">
          <router-link
            to="/"
            :class="$route.path === '/' ? 'router-link-exact-active' : ''"
            >首页</router-link
          >
        </div>
        <div style="margin-left: 56px; position: relative">
          <router-link
            to="/about"
            :class="$route.path === '/about' ? 'router-link-exact-active' : ''"
            >关于我们</router-link
          >
        </div>
        <div style="margin-left: 56px; position: relative">
          <router-link
            to="/honor"
            :class="$route.path === '/honor' ? 'router-link-exact-active' : ''"
            >荣誉资质</router-link
          >
        </div>
        <div style="margin-left: 56px; position: relative">
          <router-link
            to="/product"
            :class="
              $route.path === '/product' ? 'router-link-exact-active' : ''
            "
            >业务和产品</router-link
          >
        </div>
        <div style="margin-left: 56px; position: relative">
          <router-link
            to="/service"
            :class="
              $route.path === '/service' ? 'router-link-exact-active' : ''
            "
            >售后服务体系</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavComp",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-nav {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 15px;
  z-index: 100;
  a {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 21px;
    text-decoration: none;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: transparent;
      position: absolute;
      bottom: -5px;
    }
    &.router-link-exact-active:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: -5px;
    }
  }
}
</style>
