<template>
  <div class="count-info-box">
    <div class="count-info-item">
      <div class="count-info-item-count">
        <countTo separator="" :startVal='0' :endVal='2014' :duration='1000'></countTo>年
      </div>
      <div class="count-info-item-title">成立于</div>
    </div>
    <div class="count-info-item">
      <div class="count-info-item-count">
        <countTo separator="" :startVal='0' :endVal='1000' :duration='1000'></countTo>万元
      </div>
      <div class="count-info-item-title">注册资金</div>
    </div>
    <div class="count-info-item">
      <div class="count-info-item-count">
        <countTo separator="" :startVal='0' :endVal='5000' :duration='1000'></countTo>万元+
      </div>
      <div class="count-info-item-title">年营业额</div>
    </div>
    <div class="count-info-item">
      <div class="count-info-item-count">
        <countTo separator="" :startVal='0' :endVal='22' :duration='1000'></countTo>项
      </div>
      <div class="count-info-item-title">软件著作权</div>
    </div>
    <div class="count-info-item">
      <div class="count-info-item-count">
        <countTo separator="" :startVal='0' :endVal='6' :duration='1000'></countTo>项
      </div>
      <div class="count-info-item-title">实用新型专利</div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  name: 'CountToComp',
  components: {
    countTo
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.count-info-box{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  .count-info-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .count-info-item-count{
      width: 150px;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #1477E8;
      line-height: 31px;
      margin-bottom: 12px;
    }
    .count-info-item-title{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>
