export default {
  namespaced: true,
  state: {
    bodyWidth: document.body.clientWidth,
  },
  mutations: {
    SET_bodyWidth: (state, bodyWidth) => {
      state.bodyWidth = bodyWidth
    }
  },

  actions: {

  }
}
