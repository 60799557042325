<template>
  <div>
    <div class="topbgbox" :style="{height: topBgHeight,}">
      <img @click="toAbout" style="display: block;cursor: pointer;height: 100%;" src="../assets/banner/home.png" alt="">
    </div>
    <div class="gaikuang">
      <div class="content" style="position: relative;display: flex;justify-content: space-between;height: 100%;">
        <div style="position: absolute;top: -40px;">
          <DescribeComp>
            <CountToComp></CountToComp>
          </DescribeComp>
        </div>
        <div style="padding-top: 137px;">
          <div style="display: flex;align-items: flex-end;margin-bottom: 62px;">
            <div style="font-size: 30px;font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;font-weight: bold;color: #333333;line-height: 40px;">陈信概况</div>
            <div style="font-size: 12px;font-family: MicrosoftYaHei;color: #B1B2B5;line-height: 16px;margin-left: 13px;margin-right: 12px;">CORE BUSINESS</div>
            <div style="flex:1;height: 1px;border-bottom: 1px solid #B1B2B5;"></div>
          </div>
          <div style="line-height: 28px;">
            <div class="gaikuang-info">
              广州陈信信息科技有限公司成立于2014年，注册资金1000万元。以信息化系统集成、应用系统开发、运维服务和提供行业解决方案为主的综合性高新技术企业。
            </div>
            <br/>
            <div class="gaikuang-info">
              公司多年来致力致力于为政府、教育、企事业等客户提供信息化规划设计、管理咨询和行业解决方案等专业服务，主要聚焦：以云计算、大数据为核心的智慧政务、智慧教育、智能物联网、智慧党建、智慧文旅等解决方案。自成立以来，公司始终坚持”诚信为本，创新引领”的企业初衷，脚踏实地，务实求真，得到了客户和合作伙伴的高度认可。
            </div>
          </div>
          <div style="margin-top: 88px;">
            <div @click="toAbout" class="btn-about" style="">
              <span style="margin-right: 18px;">了解更多</span>
              <span class="sanjiao"></span>
            </div>
          </div>
        </div>
        <div style="padding-top: 161px;">
          <div style="width: 373px;height: 384px;background: #C9E3FF;border-radius: 1px;position: relative;">
            <img style="position: absolute;top: -24px;left: -28px;" src="../assets/gaikuang.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="content" style="padding-top: 63px;">
        <div style="display: flex;align-items: flex-end;margin-bottom: 21px;">
          <div style="font-size: 30px;font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;font-weight: bold;color: #FFFFFF;line-height: 40px;">业务和产品</div>
          <div style="font-size: 12px;font-family: MicrosoftYaHei;color: rgba(255,255,255,0.5);line-height: 16px;margin-left: 13px;margin-right: 12px;">BUSINESS & PRODUCTS</div>
          <div style="flex:1;height: 1px;border-bottom: 1px solid rgba(255,255,255,0.43)"></div>
        </div>
        <div style="font-size: 14px;font-family: MicrosoftYaHei;color: #FFFFFF;line-height: 28px;text-align: left;opacity: 0.88;">
          公司自成立以来一直致力于为客户提供专业的的信息化系统集成整体解决方案和服务。既有销售硬件设备，同时重视科技创新自主研发软件系统。公司现有主营产品有智慧校园云服务平台、智能家居物联网系统、智慧党建平台、智慧 文旅系统等，涉及民用、政府和院校等领域，公司经过多年的研发和优化升级，取得了越来越多的客户和合作伙伴的信任和支持。
        </div>
        <div class="product-box">
          <div :style="{left: index===productList.length-1?'':getLeft(index)+'px',right: index===productList.length-1?'0px':'', background: colorMap[index]}" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" :class="currentIndex===index?'active':''" class="product-item" v-for="(item,index) in productList" :key="index">
            <div class="product-item-top">
              <div class="product-item-top-index">{{item.index}}</div>
              <div class="product-item-top-title">{{item.title}}</div>
            </div>
            <div v-if="currentIndex!==index" style="padding-left: 17px;text-align: left;">
              <img src="../assets/icon/product0.png" alt="">
            </div>
            <div v-if="currentIndex===index" style="opacity: 0.88;letter-spacing: 0.83px;padding-left: 17px;padding-right: 32px;text-align: left;font-size: 12px;font-family: MicrosoftYaHei;color: #FFFFFF;line-height: 22px;">
              {{item.desc}}
            </div>
            <img class="product-item-icon" :src="item.icon" alt="">
            <div @click="toProduct(item)" v-if="currentIndex===index" class="more-btn">
              <span style="margin-right: 17px;">了解更多</span>
              <span class="sanjiao"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jiagou">
      <div class="content" style="padding-top: 60px">
        <div style="display: flex;align-items: flex-end;margin-bottom: 51px;">
          <div style="font-size: 30px;font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;font-weight: bold;color: #333333;line-height: 40px;">公司组织架构</div>
          <div style="font-size: 12px;font-family: MicrosoftYaHei;color: #B1B2B5;line-height: 16px;margin-left: 13px;margin-right: 12px;">ORGANIZATIONAL STRUCTURE</div>
          <div style="flex:1;height: 1px;border-bottom: 1px solid #B1B2B5"></div>
        </div>
        <img src="../assets/jiagou.png" alt="">
      </div>
    </div>
    <div class="partner">
      <div class="content" style="padding-top: 60px;padding-bottom: 58px;">
        <div style="display: flex;align-items: flex-end;margin-bottom: 65px;">
          <div style="font-size: 30px;font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;font-weight: bold;color: #000000;line-height: 40px;">合作伙伴</div>
          <div style="font-size: 12px;font-family: MicrosoftYaHei;color: #BBBDC1;line-height: 16px;margin-left: 13px;margin-right: 12px;">COOPERATIVE PARTNER</div>
          <div style="flex:1;height: 1px;border-bottom: 1px solid #B1B2B5"></div>
        </div>
        <div class="partner-box">
<!--          <img src="../assets/partner/1.png" alt="">-->
<!--          <img src="../assets/partner/2.png" alt="">-->
<!--          <img src="../assets/partner/3.png" alt="">-->
          <div class="partner-item">
            <img src="../assets/partner/4.png" alt="">
          </div>
          <div class="partner-item">
            <img src="../assets/partner/7.png" alt="">
          </div>
          <div class="partner-item">
            <img src="../assets/partner/8.png" alt="">
          </div>
          <div class="partner-item">
            <img src="../assets/partner/9.png" alt="">
          </div>
          <div class="partner-item">
            <img src="../assets/partner/10.png" alt="">
          </div>
          <div class="partner-item">
            <img src="../assets/partner/12.png" alt="">
          </div>
<!--          <img src="../assets/partner/5.png" alt="">-->
<!--          <img src="../assets/partner/6.png" alt="">-->
<!--          <img src="../assets/partner/11.png" alt="">-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DescribeComp from '@/components/DescribeComp'
import CountToComp from '@/components/CountToComp'

export default {
  name: 'HomeView',
  components: {
    DescribeComp,
    CountToComp
  },
  metaInfo: {
    title: '陈信信息',
    meta: [
      {
        name: 'keyWords',
        content: '我是首页关键字'
      },
      {
        name: 'description',
        content: '我是首页描述'
      }
    ]
  },
  data () {
    return {
      currentIndex: null,
      productList: [
        {
          title: '信息化系统集成',
          index: '01',
          desc: '在信息化系统集成方面，我司主要向各级各类政府单位、学校、和企事业部门提供多层次、多门类、全方位的综合性服务，为客户提供全方位的专业系统解决方案……',
          icon: '/productIcon/01.png',
        },
        {
          title: '智慧校园云服务平台',
          index: '02',
          desc: '智慧校园云服务平台是通过将智能设备装备到大门、教室、宿舍楼、图书馆 等区域，并联结形成校园“物联网”，以大数据云服务为支撑……',
          icon: '/productIcon/02.png',
        },
        {
          title: '智能家居物联网系统',
          index: '03',
          desc: '智能家居物联网系统是广州陈信信息科技有限公司自主研发的，应用于家庭家居智能化控制的软件系统……',
          icon: '/productIcon/03.png',
        },
        {
          title: '智慧党建平台',
          index: '04',
          desc: 'VR智慧党建教育系统设计理念新颖，将时下先进的VR及虚拟视觉呈现技术与经典红色党建文化完美结合，带给体验者震撼的互动体验……',
          icon: '/productIcon/04.png',
        },
        {
          title: '智慧文旅系统',
          index: '05',
          desc: '以文化旅游业态赋能为核心，为文体旅主管部门、景区建设智慧文旅数字化运营平台，提供定制化的文旅监管系统、统一票务管理系统、景区出入与安防系统、电子商城系统、互动营销管理……',
          icon: '/productIcon/05.png',
        }
      ],
      colorList: [
        '#3F8CF8',
        '#3F9EFF',
        '#42A6FF',
        '#43ACFF',
        '#44B0FF',
      ],
      colorMap: {
        0: 'transparent',
        1: 'transparent',
        2: 'transparent',
        3: 'transparent',
        4: 'transparent',
      }
    }
  },
  watch: {
    currentIndex: function (val) {
      const obj = {
        0: 'transparent',
        1: 'transparent',
        2: 'transparent',
        3: 'transparent',
        4: 'transparent',
      }
      if (val !== null) {
        obj[val] = this.colorList[val]
      }
      this.colorMap = obj
    }
  },
  computed: {
    topBgHeight () {
      const defaultHeight = 600;
      const defaultWidth = 1920;
      const bodyWidth = this.$store.state.common.bodyWidth;
      if (bodyWidth < defaultWidth) {
        return defaultHeight + 'px'
      } else {
        return (defaultHeight / defaultWidth) * bodyWidth + 'px'
      }
    }
  },
  methods: {
    getLeft (index) {
      return index * 239.5
    },
    toAbout () {
      this.$router.push({ path: '/about' })
    },
    toProduct (item) {
      this.$router.push({ path: '/product', query: { index: parseInt(item.index) } })
    },
    mouseEnter (index) {
      this.currentIndex = index
    },
    mouseLeave () {
      this.currentIndex = null
    }
  }
}
</script>
<style lang="scss" scoped>
.testbg{
  background: url("../assets/banner/home.png") no-repeat;
}
.gaikuang{
  height: 630px;
  background: url("../assets/homebg.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  .gaikuang-info{
    width: 746px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 28px;
    text-indent: 2em;
  }
  .btn-about{
    width: 148px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      background: #303841;
      color: #fff;

      .sanjiao{
        border-left: 7px solid #FFFFFF;
      }
    }
  }
  .sanjiao{
    width: 0px;
    height: 0px;
    border-left: 7px solid #333333;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
}
.product{
  height: 714px;
  background: url("../assets/homebg2.png") no-repeat;
  background-size: 100% 100%;
}
.jiagou{
  height: 690px;
  background: url("../assets/homebg3.png") no-repeat;
  background-size: 100% 100%;
}

.product-box{
  margin-top: 44px;
  height: 360px;
  background: rgba(255,255,255,0.2);
  box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
  border-radius: 4px;
  border: 2px solid rgba(255,255,255,0.45);
  backdrop-filter: blur(10px);
  //display: flex;
  position: relative;
  .product-item{
    width: 239px;
    height: 100%;
    padding-top: 34px;
    box-sizing: border-box;
    border-right: 1px solid rgba(255,255,255,0.45);
    position: absolute;
    transition: width 300ms linear;
    &.active{
      width: 350px;
      z-index: 1000;
      background: #3F8CF8;
    }
    &:last-child{
      border-right: none;
    }
    .product-item-icon{
      position: absolute;
      right: 1px;
      bottom: 1px;
    }
    .product-item-top{
      display: flex;
      align-items: flex-end;
      padding-left: 17px;
      .product-item-top-index{
        font-size: 40px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 52px;
        margin-right: 4px;
      }
      .product-item-top-title{
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 38px;
      }

    }
  }
}
.more-btn{
  position: absolute;
  left: 17px;
  bottom: 30px;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 21px;
  letter-spacing: 1px;

  .sanjiao{
    width: 0px;
    height: 0px;
    border-left: 7px solid #fff;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  &:hover{
    background: #FFFFFF;
    color: #0F6EFF;

    .sanjiao{
      border-left: 7px solid #0F6EFF;
    }
  }
}
.partner{
  .partner-box{
    width: 100%;
    display: flex;
    //flex-wrap: wrap;
    font-size: 0;
    .partner-item{
      flex: 1;

      img{
        width: 100%;
        flex: 1;
        height: 84px;
        border-top: 1px solid #CCCCCC;
        border-left: 1px solid #CCCCCC;
        display: inline-block;
        border-bottom: 1px solid #CCCCCC;
      }
      img:last-child{
        border-right: 1px solid #CCCCCC;
      }
    }
    //img:nth-child(4n){
    //  border-right: 1px solid #CCCCCC;
    //}
    //img:nth-child(n+9){
    //  border-bottom: 1px solid #CCCCCC;
    //}
  }
}
</style>
